import { useEffect, useState } from "react"
import { ListPageNavigationBar } from "../../../SharedComponents/ButtonComponents/ListNavigationButtons/NavigationButtons";
import { SearchInput } from "../../../SharedComponents/InputComponents/SearchInput";
import { DateLabel, FormLabel } from "../../../SharedComponents/FormComponents/FormLabel";
import { Button } from "../../../SharedComponents/ButtonComponents/Button";
import { NoTableDataToDisplay } from "../../../SharedComponents/Tables/NoData";
import { NavigateToCaseWorkflowPage } from "./CaseWorkflowUtils";
import CompletionBar from "../../../SharedComponents/GraphicComponents/CompletionBar";

const CaseWorkflowTable = ({caseWorkflows}) => {
    const [initialWorkflows, setInitialWorkflows] = useState(caseWorkflows);
    const [filteredWorkflows, setFilteredWorkflows] = useState(caseWorkflows);
    const [displayedWorkflows, setDisplayedWorkflows] = useState([]);

    const searchProperties = [
        "Description",
        "CompletedDate",
        "NextCaseWorkflowTask.Description"
    ];

    useEffect(() => {
        if(filteredWorkflows && filteredWorkflows.length === 0) {
            setDisplayedWorkflows(null);
        }
    }, [filteredWorkflows])

    return (
        <div className="react-shared-table">
            {initialWorkflows && (
                <div style={{paddingBottom: "0.3rem"}}>
                    <SearchInput
                        data={initialWorkflows}
                        setFilteredData={setFilteredWorkflows}
                        filterProperties={searchProperties}
                    />
                </div>
            )}
            <table className='background-color'>
                <thead>
                <tr>
                    <th>Workflow</th>
                    <th>Date</th>
                    <th>Next Task</th>
                    <th>% Complete</th>
                </tr>
                </thead>
                <tbody>
                {displayedWorkflows &&
                    displayedWorkflows.map((item, index) => (
                    <tr key={index} onClick={() => NavigateToCaseWorkflowPage(item.CaseId, item.CaseWorkflowId)} className="clickable">
                        <td>
                            <FormLabel displayValue={item.Description ?? ""} clickable={true}/>
                        </td>
                        <td>
                            <DateLabel displayValue={item.CompletedDate ?? ""} clickable={true}/>
                        </td>
                        <td>
                            <FormLabel displayValue={item.NextCaseWorkflowTask?.Description ?? ""} clickable={true}/>
                        </td>
                        <td>
                            <CompletionBar completion={item.PercentageCompleted ?? 0}/>
                        </td>
                    </tr>
                ))}
                {!displayedWorkflows && (
                    <NoTableDataToDisplay
                    missingDataDescription='case workflows'
                    numberOfColumnsToSpan={4}
                    />
                )}
                </tbody>
                {filteredWorkflows && filteredWorkflows.length > 0 && (
                    <div style={{ marginTop: "0.1rem" }}>
                    <ListPageNavigationBar
                        allItems={filteredWorkflows}
                        itemsPerPage={10}
                        setItemsToRender={setDisplayedWorkflows}
                    />
                    </div>
                )}
            </table>
            <div style={{marginTop: "0.2rem"}}>
                <Button
                    label='Add Workflow'
                    handleClick={() => NavigateToCaseWorkflowPage()}
                />
            </div>
        </div>
    )
}
export default CaseWorkflowTable