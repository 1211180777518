import { useEffect, useState } from "react"
import { CallStates } from "../../../SharedComponents/AppStates/Utils";
import ComponentState from "../../../SharedComponents/AppStates/ComponentState";
import { GetUsedAndRemainingTreatmentsStats } from "./Transactions";
import { NoTableDataToDisplay } from "../../../SharedComponents/Tables/NoData";

const UsedAndRemainingTreatmentsTable = ({api, caseId}) => {
    const [usedAndRemainingTreatments, setUsedAndRemainingTreatments] = useState(null);

    const [callState, setCallState] = useState(CallStates.INITIAL);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        GetUsedAndRemainingTreatmentsStats(api, caseId, setCallState)
            .then((data) => {
                setCallState(CallStates.LOADED);
                setUsedAndRemainingTreatments(data)
            })
            .catch((e) => {
                setErrorMessage("Failed to load used and remaining treatment information.");
                setCallState(CallStates.ERROR);
            })
    }, []);

 
  return (
    <ComponentState callState={callState} errorMessage={errorMessage}>
        <div className="react-shared-table">
            <table className='background-color'>
            <thead>
                <tr>
                    <th>Pathway</th>
                    <th>Used</th>
                    <th>Remaining</th>
                </tr>
            </thead>
            <tbody>
                {usedAndRemainingTreatments && usedAndRemainingTreatments.map((treatment, index) => (
                    <tr key={index}>
                        <td>{treatment.ServiceChargeDescription}</td>
                        <td>{treatment.Used}</td>
                        <td>{treatment.Remaining}</td>
                    </tr>
                ))}
                {usedAndRemainingTreatments && usedAndRemainingTreatments.length === 0 && 
                    <NoTableDataToDisplay missingDataDescription="treatment" numberOfColumnsToSpan={3}/>
                }
            </tbody>
        </table>
        </div>
    </ComponentState>
  )
}
export default UsedAndRemainingTreatmentsTable