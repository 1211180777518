import React, {useState, useEffect, useCallback} from 'react';
import FacilityName from './FacilityName';

const Search = ({
    api, 
    partyID, 
    userLocations, 
    ranking, 
    setSearchResult, 
    setUserLocations,
    postcodeCheckbox, 
    setPostcodeCheckbox,
    services,
    setLoader,
    setError,
    setInitialSearchLoad,
    initialSearchLoad,
    setInitialSearchLoadComplete,
    initialSearchLoadComplete,
    fetchCoordinateFromGoogleMap,
    gender, 
    setGender,
    caseID}) => {

    const [facilityName, setFacilityName] = useState('');
    const [facilityPostcode, setFacilityPostcode] = useState('');
    const [clinicianLastName, setClinicianLastName] = useState();
    const [clinicianFirstName, setClinicianFirstName] = useState();

    const [rankingsArray, setRankingsArray] = useState([]);
    const [modalitiesArray, setModalitiesArray] = useState([]);

    const [altPostcode, setAltPostcode] = useState("");

    // State for button that toggles advanced search
    const [toggle, setToggle] = useState(false);

    // State for adjusting distance for advance search. Default value will be 20.
    const [searchDistance, setSearchDistance] = useState(10);

    // State for disability access checkbox
    const [disabilityAccessCheckbox, setDisabilityAccessCheckbox] = useState(false);

    // State for disability access checkbox
    const [parkingCheckbox, setParkingCheckbox] = useState(false);

    const [CaseID, setcaseID] = useState("");
   
    // // State to store coordinate retrieved from google maps api using alternative postcode
    // const [altCoordinates, setAltCoordinates] = useState({});

    const setUserLocationWhenAltCoordinateIsReady = useCallback((altCoordinates, altPostcode) => {
        console.log(altCoordinates, altPostcode)
        if (Object.keys(altCoordinates).length !== 0) {
            setUserLocations((prev) => {
                return {
                    ...prev,
                    alt: {
                        postcode: altPostcode,
                        coordinate: altCoordinates
                    }
                };
            });
        }
    },[setUserLocations])

    // Single function to handle state of postcode checkboxes.
    // - Only one checkbox can be checked at a time.
    const handlePostcodeCheckbox = (event) => {
        const {name, checked} = event.target;
        setPostcodeCheckbox((prevCheckboxes)=>({
            ...prevCheckboxes,
            [name]: checked
        }))
    }

    // Function that will make API request to search for clinics.
    const searchHandler = useCallback((e) => {
        // don't reload the page
        if( e !== undefined){
            e.preventDefault();
        }

        setLoader(true)

        let params = {}

        // This function is used for searchClinic api request.
        const FacilityListHandler = (result) => {
            let resultArray;
            // currently, when search is made with alternative postcode, result array will
            // not be inside FacilityList but apiResult itself. This conditional statement
            // is used to determines whether to store FacilityList or apiResult itself as
            // an array to be set to the resultArray variable defined above.
            if(result.apiResult.FacilityList === undefined && result.apiResult.length > 0){
                resultArray = result.apiResult;
            } else if (result.apiResult.FacilityList !== undefined) {
                resultArray = result.apiResult.FacilityList;                
            }

            const closestIndex = result.apiResult.ClosestIndex;
            //this maps over the resultArray but closestIndex can be undefined if apiResult is the array iteself i.e. if line 92 resolves to true.
            //this map also changes the ClosestIndex property from and integer to a boolean! Api sets as an integer and it is used as a boolean later on.
            resultArray = resultArray.map((clinic, index)=>{
                if(index === closestIndex){
                    clinic.ClosestIndex = true;
                } else {
                    clinic.ClosestIndex = false;
                }
                return clinic;
            })

            if(resultArray !== undefined){
                setError('API response: ', result)

                if(resultArray === undefined){
                    setSearchResult([]);
                }else if(resultArray !== undefined ){
                    setSearchResult(resultArray);
                }
            } else if(resultArray === undefined){
                setError('apiResult doesnt have FacilityList', result);
                setSearchResult([]);
            }
        }

        // This code runs when alternative Postcode checkbox is ticked.
        if(postcodeCheckbox.altPostcodeCheckbox === true){

            if(altPostcode !== ""){
                if(postcodeCheckbox.IPRSClinicsOnlyCheckbox === true){
                    params.flagIPRSClinics = true
                } else if (postcodeCheckbox.IPRSClinicsOnlyCheckbox === false){
                    params.flagIPRSClinics = false
                }
                params = {
                    PatientID: parseInt(partyID), 
                    Postcode: altPostcode, 
                    FromRecord: "0", 
                    ToRecord: "1",
                    SearchMode: "5",
                    Quantity: "10", 
                    SearchDistance: searchDistance, 
                    ModalityList: modalitiesArray,
                    PracticeRankingList: rankingsArray,
                    ClinicianGender: gender,
                    PracticeName: facilityName,
                    PracticePostcode: facilityPostcode,
                    ClinicianFirstName: clinicianFirstName,
                    ClinicianLastName: clinicianLastName,
                    DisabilityAccess: disabilityAccessCheckbox,
                    Parking: parkingCheckbox,
                    FlagIPRSClinics: params.flagIPRSClinics,
                    CaseID :caseID
                }
               
                // const altPostcodeCoordinates = fetchCoordinateFromGoogleMap(altPostcode);

                api.transact("PracticeSearch", params)
                    .then(r=>{
                        // The response is given as an argument to FacilityListHandler
                        // function which will set the searchResult state with
                        // list of clinics to populate the clinic list and the 
                        // markers on the map.
                        FacilityListHandler(r);

                        // When alternative postcode is used, the marker is set
                        // after receiving the API response.
                        // API Response no longer has coordinates, this block needs
                        // to make request to googleapi to retrieve them from the browser.
                        if(r!==undefined && altPostcode !== ""){
                            // if r is not undefined, it means the postcode is highly likely
                            // a valid one, thus relatively safe to assume it can be used
                            // to make a request to googleapi to retrieve the coordinates.

                            fetchCoordinateFromGoogleMap(altPostcode)
                            .then(r=>{
                                // This function will set the coordinates for altPostcode and update the 
                                // userLocations state which will plot the alternative postcode marker
                                // on the map.
                                setUserLocationWhenAltCoordinateIsReady(r, altPostcode)
                            })
                            .catch(e=>{
                                console.error(e);
                            })
                        }

                        setLoader(false)

                        if(r.apiResult.length === 0){
                            setError('Search complete, no clinic has been found for given postcode.')
                        } else {
                            setError(null)
                        }
                    })
                    .catch(e=>{
                        setError(e.message);
                        setLoader(false)
                    })
            } else {
                console.log('altPostcode is empty.');
                setLoader(false)
            }
            
        // This code will run when alternative postcode is not ticked
        // and use the home or work postcode to search with.
        } else if (postcodeCheckbox.altPostcodeCheckbox === false){
            if(postcodeCheckbox.IPRSClinicsOnlyCheckbox === true){
                params.flagIPRSClinics = true
            } else if (postcodeCheckbox.IPRSClinicsOnlyCheckbox === false){
                params.flagIPRSClinics = false
            }
            params = {
                PatientID: parseInt(partyID), 
                SearchFromHome: "false", 
                SearchFromWork: "false", 
                SearchMode: "5",
                Quantity: "10", 
                SearchDistance: searchDistance, 
                ModalityList: modalitiesArray,
                PracticeRankingList: rankingsArray,
                ClinicianGender: gender,
                PracticeName: facilityName,
                PracticePostcode: facilityPostcode,
                ClinicianFirstName: clinicianFirstName,
                ClinicianLastName: clinicianLastName,
                DisabilityAccess: disabilityAccessCheckbox,
                Parking: parkingCheckbox,
                FlagIPRSClinics: params.flagIPRSClinics,
                CaseID:caseID
                
            };

            if(postcodeCheckbox.homePostcode === true){
                params.SearchFromHome = "true"
            } else if (postcodeCheckbox.homePostcode === false){
                params.SearchFromHome = "false"
            }
    
            if(postcodeCheckbox.workPostcode === true){
                params.SearchFromWork = "true"
            } else if (postcodeCheckbox.workPostcode === false){
                params.SearchFromWork = "false"
            }

            api.transact('PracticeSearch', params)
                .then(r=>{
                    FacilityListHandler(r);
                    setLoader(false);
                    if(r.apiResult.length === 0){
                        setError('Search complete, no clinic has been found for given postcode.')
                    }else if(r.apiResult.FacilityList.length === 0){
                        setError('No clinics found in search.');
                    } else {
                        setError(null);
                    }
                })
                .catch(e=>{
                    setError('PracticeSearch api error: ', e);
                    setLoader(false);
                })
        }

    },[
        altPostcode, 
        searchDistance, 
        modalitiesArray, 
        rankingsArray, 
        gender, 
        facilityName, 
        facilityPostcode, 
        clinicianFirstName, 
        clinicianLastName,
        api,
        partyID,
        postcodeCheckbox,
        setError,
        setLoader,
        setSearchResult,
        fetchCoordinateFromGoogleMap,
        setUserLocationWhenAltCoordinateIsReady,
        parkingCheckbox,
        disabilityAccessCheckbox
    ])
    // initialSearchLoad state will only change when userLocations.home is not empty
    // and initialSearchLoad is false and it will only change to false once. 
    // This useEffect will trigger searchHandler when all these conditions are met.
    useEffect(()=>{
        if(initialSearchLoad === false && initialSearchLoadComplete === false){
            searchHandler();
            setInitialSearchLoadComplete(true)
        }
    },[initialSearchLoad, searchHandler, initialSearchLoadComplete, setInitialSearchLoadComplete])

    // This function will set the homePostcode to true and also set the initialSearchLoad to false
    // when useEffect below finds that userLocations.home is not empty.
    const initialSearchHomeTicker = useCallback(() => {
        setInitialSearchLoad(false);
        
    },[setInitialSearchLoad])


    // This useEffect runs when userLocation is received and sets the state of postcodeCheckbox.
    // - if home and work is both available, it should only tick home postcode.
    useEffect(()=>{
        const homePostcodeCheck = userLocations.home !== '' ? true : false;
        const workPostcodeCheck = false;
        const IPRSClinicsOnlyCheck = false;
        setPostcodeCheckbox({
            homePostcode: homePostcodeCheck,
            workPostcode: workPostcodeCheck,
            altPostcodeCheckbox: false,
            IPRSClinicsOnlyCheckbox: IPRSClinicsOnlyCheck
        })        
    },[userLocations, setPostcodeCheckbox, setInitialSearchLoad])

    // useEffect(()=>{
    //     console.log(postcodeCheckbox);
    // },[postcodeCheckbox])

    // When this component loads, initial search will be made if Postcode is available and 
    // initialSearchLoad is true.
    useEffect(()=>{
        // This should only run once when partyID is available, which will be when
        // this component first loads up.

        // when userLocations.home !== "", it means home postcode is available, thus it should
        // make the initial search when component is loaded if initialSearchLoad is set to true,
        // (and it should be true since initial state of initialSearchLoad is true)
        // When initial state of initialSearchLoad is true, it means the component has just loaded
        // and it should check whether userLocations has home post code and if it does,
        // it should run the searchHandler() function.
        if(userLocations.home !== "" || userLocations.work !== ""){
            if(initialSearchLoad === true){
                initialSearchHomeTicker();
            }
        } else {
            setError('Current patient doesnt have a home address: ', userLocations);
        }
    },[partyID, userLocations, initialSearchLoad, searchHandler, setInitialSearchLoad, initialSearchHomeTicker, setError])

    const altPostcodeHandler = (event) => {
        setAltPostcode(event.target.value)
    }

    const [searchButtonDisable, setSearchButtonDisable] = useState(true)

    const searchButtonDisabler = useCallback(() => {
        if(postcodeCheckbox.homePostcode === false && 
           postcodeCheckbox.workPostcode === false && 
           postcodeCheckbox.altPostcodeCheckbox === false){
            setSearchButtonDisable(true);
        } else {
            setSearchButtonDisable(false);
        }
    },[postcodeCheckbox])

    // This useEffect checks whether any home, work and alt postcode has been ticked.
    // If it is ticked, it will use searchButton Disabler function decide whether search button
    // is disabled or active.
    useEffect(()=>{
        searchButtonDisabler()
        if(postcodeCheckbox.homePostcode !== false && initialSearchLoad !== true){}
    },[postcodeCheckbox, searchButtonDisabler, initialSearchLoad])

    const searchDistanceHandler = (e) => {
        setSearchDistance(e.target.value)
    }
    
    const searchArray = (array, searchTerm) => {
        return array.some(item => item === searchTerm);
    }

    const toggleItemInArray = (array, item, type) => {
        const index = array.indexOf(item);
        if(index === -1){
            // Item doesn't exist in the array. Add the item.
            if(type === "ranking"){
                setRankingsArray([...array, item])
            } else if (type === "modality") {
                setModalitiesArray([...array, item])
            }
        } else {
            // Item exists in the array, so remove it
            if(type === "ranking"){
                setRankingsArray(array.filter((_, i)=> i !== index));
            } else if (type === "modality") {
                setModalitiesArray(array.filter((_, i)=> i !== index));
            }
        }
    }

    const disabilityAccessHandler = (e) => {
        setDisabilityAccessCheckbox(e.target.checked);
    }

    const parkingHandler = (e) => {
        setParkingCheckbox(e.target.checked);
    }

    const genderHandler = (e) => {
        setGender(e.target.value);
    }

    const facilityPostcodeHandler = (e) => {
        setFacilityPostcode(e.target.value);
    }

    const clinicianFirstNameHandler = (e) => {
        setClinicianFirstName(e.target.value);
    }

    const clinicianLastNameHandler = (e) => {
        setClinicianLastName(e.target.value);
    }

    return (
        <div className="onwardReferralSearchForm">
            <h1 className="searchHeading">Clinic search</h1>

            <label>
                <span>Home Postcode &nbsp;
                    {userLocations.home.postcode !== undefined ? 
                    <span className="labelPostcode">({userLocations.home.postcode})</span> : 
                    ''}
                </span>
                <input 
                    type="checkbox" 
                    name="homePostcode" 
                    disabled={userLocations.home === ""}
                    checked={postcodeCheckbox.homePostcode} 
                    onChange={handlePostcodeCheckbox}>    
                </input>
            </label>
            <label>
                <span>Work Postcode &nbsp;
                    {userLocations.work.postcode !== undefined ? 
                    <span className="labelPostcode">({userLocations.work.postcode})</span> : 
                    ''}
                </span>
                <input 
                    type="checkbox" 
                    name="workPostcode" 
                    disabled={userLocations.work === ""}
                    checked={postcodeCheckbox.workPostcode} 
                    onChange={handlePostcodeCheckbox}>
                </input>
            </label>
            <label className="altPostcodeLabel">
                <div>
                    <span>
                        Alternative PostCode
                    </span>
                    <input 
                        type="checkbox"
                        name="altPostcodeCheckbox" 
                        checked={postcodeCheckbox.altPostcodeCheckbox} 
                        onChange={handlePostcodeCheckbox}>
                    </input>
                </div>
                <div>
                    {postcodeCheckbox.altPostcodeCheckbox === true ? 
                        (<>
                            <input 
                                type="text"
                                name="altPostcode" 
                                checked={postcodeCheckbox.altPostcode}
                                onChange={altPostcodeHandler}
                            />
                        </>) : 
                        (<></>)
                    }
                </div>
            </label>
            <label className="altPostcodeLabel">
                <div>
                    <span>
                        IPRS Clinics    
                    </span>
                    <input
                        type="checkbox"
                        name="IPRSClinicsOnlyCheckbox" 
                        checked={postcodeCheckbox.IPRSClinicsOnlyCheckbox} 
                        onChange={handlePostcodeCheckbox}>
                    </input>
                </div>
            </label>
            <label className="distanceLabel">
                <div>
                    <span>Distance</span>
                    <div className="distanceInput">
                        <input 
                            className="distanceSlider"
                            type="range" 
                            name="searchDistance" 
                            min="1"
                            max="100"
                            // defaultValue="20"
                            value={searchDistance} 
                            onChange={searchDistanceHandler}>
                        </input>
                        <input 
                            className="distanceText"
                            type="text" 
                            name="searchDistance" 
                            // defaultValue="20"
                            value={searchDistance} 
                            onChange={searchDistanceHandler}>
                        </input>
                    </div>

                </div>
            </label>

            <div className="searchAndMoreButton">
                <button 
                    onClick={e => {e.preventDefault();setToggle(!toggle);}}
                    className="searchFormMoreButton"
                >
                    More {toggle !== false ? ('︽') : ('︾')}
                </button>    
            </div>

            {toggle !== false ? (
                <div>
                    <div>
                        <h3>Ranking</h3>
                        <div>
                            {ranking.map((rank, index)=>{
                                return(
                                    <label key={index}>
                                        <span>
                                            {rank.PracticeRankingDescription}
                                        </span>
                                        <input 
                                            type="checkbox"
                                            onChange={()=>toggleItemInArray(rankingsArray, rank.PracticeRankingId, 'ranking')}
                                            value={searchArray(rankingsArray, rank.PracticeRankingId)}
                                        />
                                    </label>
                                )
                            })}
                        </div>
                    </div>
                    <div>
                        <h3>Services</h3>
                        <div>
                            {services.map((service, index)=>{
                                return(
                                    <label key={index}>
                                        <span>
                                            {service.ModalityDescription}
                                        </span>
                                        <input 
                                            type="checkbox"
                                            onChange={()=>toggleItemInArray(modalitiesArray, service.pkModalityID, 'modality')}
                                            value={searchArray(modalitiesArray, service.pkModalityID)}
                                        />
                                    </label>
                                )
                            })}
                        </div>
                    </div>
                    <div>
                        <h3>Access</h3>
                        <div>
                            <label>
                                <span>
                                    Disability Access
                                </span>
                                <input 
                                    type="checkbox"
                                    onChange={disabilityAccessHandler}
                                    checked={disabilityAccessCheckbox}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                <span>
                                    Parking
                                </span>
                                <input 
                                    type="checkbox"
                                    onChange={parkingHandler}
                                    checked={parkingCheckbox}
                                />
                            </label>
                        </div>
                    </div>
                    <div>
                        <h3>Gender</h3>
                        <div>
                            <label>
                                <span>Male</span>
                                <input 
                                    type="radio" 
                                    name="gender"
                                    value="M"
                                    onChange={genderHandler}
                                />
                            </label>
                            <label>
                                <span>Female</span>
                                <input 
                                    type="radio" 
                                    name="gender"
                                    value="F"
                                    onChange={genderHandler}
                                />
                            </label>
                        </div>
                    </div>
                    <div>
                        <h3>Find a specific practice</h3>
                        <div>
                            <FacilityName 
                                api={api}
                                facilityName={facilityName}
                                setFacilityName={setFacilityName}
                            />
                            <label>
                                <div>
                                    <div>
                                        <span>
                                            Postcode
                                        </span>
                                    </div>
                                    <div>
                                        <input 
                                            className="SearchTextInputFullSize" 
                                            type="text"
                                            value={facilityPostcode}
                                            onChange={facilityPostcodeHandler}
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div>
                        <h3>Specific Clinician</h3>
                        <div>
                            <label>
                                <div>
                                    <div>
                                        <span>
                                            First Name
                                        </span>
                                    </div>
                                    <div>
                                        <input 
                                            className="SearchTextInputFullSize" 
                                            type="text"
                                            value={clinicianFirstName}
                                            onChange={clinicianFirstNameHandler}
                                        />
                                    </div>
                                </div>
                            </label>
                            <label>
                                <div>
                                    <div>
                                        <span>
                                            Last Name
                                        </span>
                                    </div>
                                    <div>
                                        <input 
                                            className="SearchTextInputFullSize" 
                                            type="text"
                                            value={clinicianLastName}
                                            onChange={clinicianLastNameHandler}
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            ) : (<></>)}

            <div className="searchAndMoreButton">
                <button
                    onClick={searchHandler}
                    disabled={searchButtonDisable}
                    className="searchPracticeButton"
                >
                    Search
                </button>
            </div>
        </div>
    )
}

export default Search