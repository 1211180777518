export const caseModel = {
    ActionFlag: 2
}

export const NavigateToCaseEventPage = (caseId = undefined, caseEventId = undefined) => {
    let url = `${window.location.origin}/Presentation/Page/PMS/CaseEventPage.aspx`;
    if(caseId && caseEventId) {
        url += "?Case=" + caseId + "&CaseEvent=" + caseEventId;
    }
    window.open(url);
    return;
}