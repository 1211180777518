import React, {useState, useEffect} from 'react';
import PasswordResetSuccess from './PasswordResetSuccess'
import Logo from "../images/logos/iprshealthnew.png";
import "../SharedComponents/Styles/LoginPageStyles/styles.css"
import "../SharedComponents/Styles/LoginPageStyles/normalize.css";
const PasswordReset = ({api, setIsPwReset, urlToken}) => {
    
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [checkedPassword, setCheckedPassword] = useState('');
    
    const [errorMessage, setErrorMessage] = useState('');
    const [errorResponse, setErrorResponse] = useState('');

    // Boolean state that will be set to false when the component loads up.
    // When 'ResetPassword' comes back with 'OK', it will set to true.
    const [successStatus, setSuccessStatus] = useState(false);

    // Change Password button handler. 
    const changePasswordButtonHandler = (e) => {
        e.preventDefault();

        const params = {
            token: urlToken,
            password: checkedPassword
        }

        api.transact("ResetPassword", 
            params
        ).then((r)=>{
            console.log(r);
            if(r.apiStatus === "OK"){
                setSuccessStatus(true);
            }
        }).catch((e)=>{
            console.log(e);
            setErrorMessage(e.apiText);
            
        })
    }

    useEffect(()=>{
        if(password1 === password2){
            setCheckedPassword(password1);
        } else {
            setCheckedPassword('');
        }
    },[password1, password2, setCheckedPassword]);

    const password1FieldsHandler = (e) => {
        e.preventDefault();
        setPassword1(e.target.value);
    };

    const password2FieldsHandler = (e) => {
        e.preventDefault();
        setPassword2(e.target.value);
    } 

    return <>
        {
            successStatus === true ?
            <PasswordResetSuccess 
                setSuccessStatus={setSuccessStatus}
                setIsPwReset={setIsPwReset}
            /> :
            <>

                <div id="passwordChangeFields">
                <div className="Logincontainer">
                <main className="mainLoginPanel">
                    <div className="mainLoginPanel__top">
                        <div className="mainLoginPanel__top-logo">
                            <img src={Logo} alt="IPRS Health Logo" />
                        </div>
                        </div>
                        <div>
                        <h1 className="mainLoginPanel__top-title">
                            Pharos Password Reset
                        </h1>
                        </div>
                    
                    <div className="mainLoginPanel__section">
                    <label className="passwordField">
                        <span>Password</span>
                        <input 
                            id="password1" 
                            type="password"
                            value={password1}
                            onFocus={(e)=>e.target.select()} // Select all on focus 
                            onChange={(e)=>password1FieldsHandler(e)}
                        />
                    </label>
                    <label className="passwordField">
                        <span>Confirm Password</span>
                        <input 
                            id="password2" 
                            type="password"
                            value={password2} 
                            onFocus={(e)=>e.target.select()} // select all on focus
                            onChange={(e)=>password2FieldsHandler(e)}
                        />
                    </label>
                <button 
                        class="changePasswordButton"
                        disabled={checkedPassword === '' ? true : false}
                        onClick={(e)=>changePasswordButtonHandler(e)}
                    >
                        Change Password
                    </button>
                    <div>
                    {errorMessage && (
                    <p className="changePasswordErrorMessage">
                        {errorMessage}
                    </p>
                    )}
                    </div>
                    </div>
                    </main>
                    </div>
                </div>  
            </>
        }
    </>
};

export default PasswordReset;
