import { useEffect } from "react";
import { IprsIconWithTextFitToContainer, RotatingIprsIconWithTextFitToContainer } from "./CallStates";
import { CallStates } from "./Utils";

const ComponentState  = ({ callState, errorMessage, children }) => {

    useEffect(() => {
    if (callState === CallStates.SAVED) {
      const timer = setTimeout(() => {
      }, 2000); // 2000 milliseconds = 2 seconds
      clearTimeout(timer); // Clean up the timer on component unmount or callState change
    } 
  }, [callState]);

  if (callState === CallStates.LOADING || callState === CallStates.INITIAL) {
    return <RotatingIprsIconWithTextFitToContainer displayText='Loading...' />;
  } else if (callState === CallStates.SAVING) {
    return <RotatingIprsIconWithTextFitToContainer displayText='Saving...' />;
  } else if (callState === CallStates.SAVED) {
    return <IprsIconWithTextFitToContainer displayText='Saved!' />;
  } else if (callState === CallStates.ERROR) {
    return <IprsIconWithTextFitToContainer displayText='Error.' message={errorMessage} />;
  } else if (callState === CallStates.SENT) {
    return <IprsIconWithTextFitToContainer displayText='Sent!' />;
  } else if (callState === CallStates.SENDING) {
    return <IprsIconWithTextFitToContainer displayText='Sending...' message={errorMessage} />;
  } else if (callState === CallStates.LOADED) {
    return children;
  }
};
export default ComponentState