import React from "react";
import ReactDOM from "react-dom";
import CasePage from "../../components/CasePage/CasePage";

const AddCasePageListsComponent = (api) => {
  try {
    const pharosCasePageRegex = new RegExp(
      "^/Presentation/Page/PMS/CasePage.aspx$"
    );
    const isPharosCasePageURL = pharosCasePageRegex.test(
      window.location.pathname
    );
    if (isPharosCasePageURL) {
      const casePageListsElem =
        document.getElementById("CasePageLists");
      casePageListsElem.innerHTML = "";
      if (null != casePageListsElem) {
        ReactDOM.render(
          <React.StrictMode>
            <CasePage api={api} caseId={document.getElementById('CaseIDHiddenField').value} />
          </React.StrictMode>,
          casePageListsElem
        );
      }
    }
  } catch (e) {
    console.log("casePageLists LOAD ERROR", e);
  }
}
export default AddCasePageListsComponent