import { useEffect, useState } from "react"
import CaseReferenceTable from "./SectionComponents/CaseReference/CaseReferenceTable";
import CaseInjuryTable from "./SectionComponents/CaseInjury/CaseInjuryTable";
import CaseStatusTable from "./SectionComponents/CaseStatuses/CaseStatusTable";
import CaseEventTable from "./SectionComponents/CaseEvent/CaseEventTable";
import CaseWorkflowTable from "./SectionComponents/CaseWorkflow/CaseWorkflowTable";
import { getCasePageDetails } from "./Transactions";
import { CallStates } from "../SharedComponents/AppStates/Utils";
import { PartitionLine } from "../SharedComponents/PageLayoutComponents/Partition";
import ComponentState from "../SharedComponents/AppStates/ComponentState";


const CasePage = ({api, caseId}) => {
    const [events, setEvents] = useState(null);
    const [injuries, setInjuries] = useState(null);
    const [references, setReferences] = useState(null);
    const [statuses, setStatuses] = useState(null);
    const [workflows, setWorkflows] = useState(null);

    const [callState, setCallState] = useState(CallStates.INITIAL);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        getCasePageDetails(api, caseId, setCallState)
            .then((data) => {
                setEvents(data.CaseEvents ?? []);
                setInjuries(data.CaseInjuries ?? []);
                setReferences(data.CaseReferences ?? []);
                setStatuses(data.CaseStatuses ?? []);
                setWorkflows(data.CaseWorkflows ?? []);
                setCallState(CallStates.LOADED);
            })
            .catch((e) => {
                setErrorMessage(e.message);
                setCallState(CallStates.ERROR);
            });
    }, [api, caseId])
    return (
        <div className="react-shared">
            <ComponentState callState={callState} errorMessage={errorMessage}>
                {references && <>
                    <div style={{padding: "1rem 0"}}>
                        <CaseReferenceTable api={api} caseReferences={references}/>
                    </div> 
                    <PartitionLine/>
                </>}
                {injuries && <>
                    <div style={{padding: "1rem 0"}}>
                        <CaseInjuryTable caseInjuries={injuries}/>
                    </div>
                    <PartitionLine/>
                </>}
                {statuses && <>
                    <div style={{padding: "1rem 0"}}>
                        <CaseStatusTable caseStatuses={statuses}/>
                    </div>
                    <PartitionLine/>
                </>}
                {events && <>
                    <div style={{padding: "1rem 0"}}>
                        <CaseEventTable api={api} caseEvents={events}/>
                    </div>
                    <PartitionLine/>
                </>}
                {workflows &&
                    <div style={{padding: "1rem 0"}}>
                        <CaseWorkflowTable caseWorkflows={workflows}/>
                    </div>}
            </ComponentState>
        </div>
    );
}
export default CasePage