import { CallStates } from "../../../SharedComponents/AppStates/Utils";

export const GetUsedAndRemainingTreatmentsStats = (api, caseId, setCallState) => {
  return api
    .transact("GetUsedAndRemainingTreatmentsOnCase", { caseId: caseId })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
}