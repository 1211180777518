import React from "react";
import ReactDOM from "react-dom";
import UsedAndRemainingTreatmentsTable from "../../components/eform/TreatmentInformation/UsedAndRemaining/UsedAndRemainingTreatmentsTable";

const RenderUsedAndRemainingTreatmentsTable = (api, caseId) =>
{
    const contentDiv = document.getElementById('UsedAndRemainingTreatmentsTable');
    if (null != contentDiv && caseId) {
        ReactDOM.render(
            <React.StrictMode>
                <UsedAndRemainingTreatmentsTable api={api} caseId={caseId} />
            </React.StrictMode>,
            contentDiv
        );
    }
}

export default RenderUsedAndRemainingTreatmentsTable;