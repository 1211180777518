import { useEffect, useState } from "react"
import { ListPageNavigationBar } from "../../../SharedComponents/ButtonComponents/ListNavigationButtons/NavigationButtons";
import { SearchInput } from "../../../SharedComponents/InputComponents/SearchInput";
import { DateLabel, FormLabel } from "../../../SharedComponents/FormComponents/FormLabel";
import { Button } from "../../../SharedComponents/ButtonComponents/Button";
import { NoTableDataToDisplay } from "../../../SharedComponents/Tables/NoData";
import { DeleteCaseEvent } from "./EventTableTransactions";
import CaseEvent from "./CaseEvent";
import { NavigateToCaseEventPage } from "./Utils";
import PageState from "../../../SharedComponents/AppStates/PageState";
import { CallStates } from "../../../SharedComponents/AppStates/Utils";
import ComponentState from "../../../SharedComponents/AppStates/ComponentState";

const CaseEventTable = ({api, caseEvents}) => {
    const [initialEvents, setInitialEvents] = useState(null);
    const [filteredEvents, setFilteredEvents] = useState(null);
    const [displayedEvents, setDiplayedEvents] = useState([]);

    const [callState, setCallState] = useState(CallStates.LOADED);
    const [errorMessage, setErrorMessage] = useState(null);

    const searchProperties = [
        "EventDate",
        "EventTypeDescription",
        "Details",
        "InsertedDate"
    ];

    useEffect(() => {
        setInitialEvents(caseEvents);
        setFilteredEvents(caseEvents);
    }, [])

    useEffect(() => {
        if(filteredEvents && filteredEvents.length === 0) {
            setDiplayedEvents(null);
        }
    }, [filteredEvents])

    const handleDeleteClick = (e, caseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if(window.confirm("Are you sure you want to delete this event?")) {
        setCallState(CallStates.SAVING);
        DeleteCaseEvent(api, caseEvent, setCallState)
            .then(() => {
                const updatedCaseEvents = initialEvents.filter(item => item.CaseEventId !== caseEvent.CaseEventId);
                setInitialEvents(updatedCaseEvents);
                setFilteredEvents(updatedCaseEvents);
                setCallState(CallStates.SAVED);// this will display for 2 seconds
                setCallState(CallStates.LOADED);// this then renders the component again
            })
            .catch((e) => {
                setErrorMessage(e.message);
                setCallState(CallStates.ERROR);
            })
        }
    }

    return (
        <ComponentState callState={callState} errorMessage={errorMessage}> 
            <div className="react-shared-table">
                {initialEvents && (
                    <div style={{paddingBottom: "0.3rem"}}>
                        <SearchInput
                            data={initialEvents}
                            setFilteredData={setFilteredEvents}
                            filterProperties={searchProperties}
                        />
                    </div>
                )}
                <table className='background-color'>
                    <thead>
                    <tr>
                        <th>Event Date</th>
                        <th>Event</th>
                        <th>Details</th>
                        <th>Inserted Date</th>
                        {displayedEvents && <th></th>}
                    </tr>
                    </thead>
                    <tbody>
                    {displayedEvents &&
                        displayedEvents.map((item, index) => (
                        <tr key={index} onClick={() => NavigateToCaseEventPage(item.CaseId, item.CaseEventId)} className="clickable">
                            <td>
                                <DateLabel displayValue={item.EventDate ?? ""} clickable={true}/>
                            </td>
                            <td>
                                <FormLabel displayValue={item.EventTypeDescription ?? ""} clickable={true}/>
                            </td>
                            <td>
                                <FormLabel displayValue={item.Details ?? ""} clickable={true}/>
                            </td>
                            <td>
                                <DateLabel displayValue={item.InsertedDate ?? ""} clickable={true}/>
                            </td>
                            <td>
                                <Button
                                    label='Delete'
                                    handleClick={(e) => handleDeleteClick(e, item)}
                                />
                            </td>
                        </tr>
                    ))}
                    {!displayedEvents && (
                        <NoTableDataToDisplay
                        missingDataDescription='case events'
                        numberOfColumnsToSpan={4}
                        />
                    )}
                    </tbody>
                    {filteredEvents && filteredEvents.length > 0 && (
                        <div style={{ marginTop: "0.1rem" }}>
                        <ListPageNavigationBar
                            allItems={filteredEvents}
                            itemsPerPage={10}
                            setItemsToRender={setDiplayedEvents}
                        />
                        </div>
                    )}
                </table>
                <div style={{marginTop: "0.2rem"}}>
                    <Button
                        label='Add Event'
                        handleClick={() => NavigateToCaseEventPage()}
                    />
                </div>
            </div>
        </ComponentState>
        
    )
}
export default CaseEventTable