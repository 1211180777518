import "./CompletionBarStyles.css";

const CompletionBar = ({ completion }) => {
  if (typeof completion !== 'number' || completion < 0 || completion > 1) {
    console.error('Invalid prop: "completion" should be a number between 0 and 1.');
    return null;
  }

  const completedStyle = {
    width: `${completion * 100}%`,
  };

  return (
    <div className="completion-bar">
      <div className="completed" style={completedStyle}></div>
      <div className="completion-text">{`${(completion * 100).toFixed(0)}%`}</div>
    </div>
  );
};
export default CompletionBar