import { useState, useEffect } from "react"
import { ListPageNavigationBar } from "../../../SharedComponents/ButtonComponents/ListNavigationButtons/NavigationButtons";
import { SearchInput } from "../../../SharedComponents/InputComponents/SearchInput";
import { FormLabel } from "../../../SharedComponents/FormComponents/FormLabel";
import { Button } from "../../../SharedComponents/ButtonComponents/Button";
import { NoTableDataToDisplay } from "../../../SharedComponents/Tables/NoData";
import { NavigateToCaseReferencePage } from "./CaseReferenceUtils";
import { DeleteCaseReference } from "./CaseReferenceTransactions";
import ComponentState from "../../../SharedComponents/AppStates/ComponentState";
import { CallStates } from "../../../SharedComponents/AppStates/Utils";

const CaseReferenceTable = ({ api, caseReferences}) => {
    const [initialReferences, setInitialReferences] = useState(caseReferences);
    const [filteredReferences, setFilteredReferences] = useState(caseReferences);
    const [displayedReferences, setDisplayedReferences] = useState([]);

    const [callState, setCallState] = useState(CallStates.LOADED);
    const [errorMessage, setErrorMessage] = useState(null);

    const searchProperties = [
        "ReferenceTypeDescription",
        "ReferenceValue"
    ];

    useEffect(() => {
        if(filteredReferences && filteredReferences.length === 0) {
            setDisplayedReferences(null);
        }
    }, [filteredReferences])

    const handleDeleteClick = (e, caseReference) => {
        e.preventDefault();
        e.stopPropagation();
        if(window.confirm("Are you sure you want to delete this reference?")) {
        setCallState(CallStates.SAVING);
        DeleteCaseReference(api, caseReference, setCallState)
            .then(() => {
                const updatedCaseReferences = initialReferences.filter(item => item.CaseReferenceId !== caseReference.CaseReferenceId);
                setInitialReferences(updatedCaseReferences);
                setFilteredReferences(updatedCaseReferences);
                setCallState(CallStates.SAVED);// this will display for 2 seconds
                setCallState(CallStates.LOADED);// this then renders the component again
            })
            .catch((e) => {
                setErrorMessage(e.message);
                setCallState(CallStates.ERROR);
            })
        }
    }

    return (
        <ComponentState callState={callState} errorMessage={errorMessage}>
            <div className="react-shared-table">
                {initialReferences && (
                    <div style={{paddingBottom: "0.3rem"}}>
                        <SearchInput
                            data={initialReferences}
                            setFilteredData={setFilteredReferences}
                            filterProperties={searchProperties}
                        />
                    </div>
                )}
                <table className='background-color'>
                    <thead>
                    <tr>
                        <th>Reference Type</th>
                        <th>Reference Value</th>
                        {displayedReferences && <th></th>}
                    </tr>
                    </thead>
                    <tbody>
                    {displayedReferences &&
                        displayedReferences.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <FormLabel displayValue={item.ReferenceTypeDescription ?? ""} clickable={false}/>
                            </td>
                            <td>
                                <FormLabel displayValue={item.ReferenceValue ?? ""} clickable={false}/>
                            </td>
                            <td style={{width: "15%"}}>
                                <Button
                                    label='Delete'
                                    handleClick={(e) => handleDeleteClick(e, item)}
                                />
                            </td>
                        </tr>
                    ))}
                    {!displayedReferences && (
                        <NoTableDataToDisplay
                        missingDataDescription='case references'
                        numberOfColumnsToSpan={2}
                        />
                    )}
                    </tbody>
                    {filteredReferences && filteredReferences.length > 0 && (
                        <div style={{ marginTop: "0.1rem" }}>
                        <ListPageNavigationBar
                            allItems={filteredReferences}
                            itemsPerPage={10}
                            setItemsToRender={setDisplayedReferences}
                        />
                        </div>
                    )}
                </table>
                <div style={{marginTop: "0.2rem"}}>
                    <Button
                        label='Add Reference'
                        handleClick={() => NavigateToCaseReferencePage()}
                    />
                </div>
            </div>
        </ComponentState>
        
    )
}
export default CaseReferenceTable