import { useState, useEffect } from "react"
import { ListPageNavigationBar } from "../../../SharedComponents/ButtonComponents/ListNavigationButtons/NavigationButtons";
import { SearchInput } from "../../../SharedComponents/InputComponents/SearchInput";
import { DateLabel, FormLabel } from "../../../SharedComponents/FormComponents/FormLabel";
import { NoTableDataToDisplay } from "../../../SharedComponents/Tables/NoData";

const CaseStatusTable = ({caseStatuses}) => {
    const [initialStatuses, setInitialStatuses] = useState(caseStatuses);
    const [filteredStatuses, setFilteredStatuses] = useState(caseStatuses);
    const [displayedStatuses, setDisplayedStatuses] = useState([]);

    const searchProperties = [
        "DateFrom",
        "StatusDescription",
        "Reason",
        "Comments"
    ];

    useEffect(() => {
        if(filteredStatuses && filteredStatuses.length === 0) {
            setDisplayedStatuses(null);
        }
    }, [filteredStatuses])

    return (
        <div className="react-shared-table">
            {initialStatuses && (
                <div style={{paddingBottom: "0.3rem"}}>
                    <SearchInput
                        data={initialStatuses}
                        setFilteredData={setFilteredStatuses}
                        filterProperties={searchProperties}
                    />
                </div>
            )}
            <table className='background-color'>
                <thead>
                <tr>
                    <th>Date From</th>
                    <th>Status</th>
                    <th>Reason</th>
                    <th>Comments</th>
                </tr>
                </thead>
                <tbody>
                {displayedStatuses &&
                    displayedStatuses.map((item, index) => (
                    <tr key={index}>
                        <td>
                            <DateLabel displayValue={item.DateFrom ?? ""}/>
                        </td>
                        <td>
                            <FormLabel displayValue={item.StatusDescription ?? ""} />
                        </td>
                        <td>
                            <FormLabel displayValue={item.Reason ?? ""} />
                        </td>
                        <td>
                            <FormLabel displayValue={item.Comments ?? ""} />
                        </td>
                    </tr>
                ))}
                {!displayedStatuses && (
                    <NoTableDataToDisplay
                    missingDataDescription='case statuses'
                    numberOfColumnsToSpan={4}
                    />
                )}
                </tbody>
                {filteredStatuses && filteredStatuses.length > 0 && (
                    <div style={{ marginTop: "0.1rem" }}>
                    <ListPageNavigationBar
                        allItems={filteredStatuses}
                        itemsPerPage={10}
                        setItemsToRender={setDisplayedStatuses}
                    />
                    </div>
                )}
            </table>
        </div>
    )
}
export default CaseStatusTable