import { CallStates } from "../../../SharedComponents/AppStates/Utils";

export const DeleteCaseReference = (api, caseReference, setCallState) => {
  return api
    .transact("DeleteCaseReferenceByCaseReferenceId", { caseReferenceId: caseReference.CaseReferenceId })
    .then((response) => {
        if (response.apiStatus !== "OK") {
            return Promise.reject(new Error('Failed to delete case event'));
        }
        setCallState(CallStates.SUCCESS);
        return;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
}