import { useState, useEffect } from "react"
import { ListPageNavigationBar } from "../../../SharedComponents/ButtonComponents/ListNavigationButtons/NavigationButtons";
import { SearchInput } from "../../../SharedComponents/InputComponents/SearchInput";
import { CheckboxDisabled, FormLabel } from "../../../SharedComponents/FormComponents/FormLabel";
import { Button } from "../../../SharedComponents/ButtonComponents/Button";
import { NoTableDataToDisplay } from "../../../SharedComponents/Tables/NoData";
import { NavigateToCaseInjuryPage } from "./CaseInjuryUtils";

const CaseInjuryTable = ({caseInjuries}) => {
    const [initialInjuries, setInitialInjuries] = useState(caseInjuries);
    const [filteredInjuries, setFilteredInjuries] = useState(caseInjuries);
    const [displayedInjuries, setDisplayedInjuries] = useState([]);

    const searchProperties = [
        "Ranking",
        "MedicalRegion",
        "NonMedicalRegion",
        "TypeDescription"
    ];

    useEffect(() => {
        if(filteredInjuries && filteredInjuries.length === 0) {
            setDisplayedInjuries(null);
        }
    }, [filteredInjuries])

    return (
        <div className="react-shared-table">
            {initialInjuries && (
                <div style={{paddingBottom: "0.3rem"}}>
                    <SearchInput
                        data={initialInjuries}
                        setFilteredData={setFilteredInjuries}
                        filterProperties={searchProperties}
                    />
                </div>
            )}
            <table className='background-color'>
                <thead>
                <tr>
                    <th>Ranking</th>
                    <th>Medical Injury Desc</th>
                    <th>Non Medical Injury Desc</th>
                    <th>Injury Type</th>
                    <th>Side</th>
                    <th>Comments</th>
                    <th>Added at Referral</th>
                </tr>
                </thead>
                <tbody>
                {displayedInjuries &&
                    displayedInjuries.map((item, index) => (
                    <tr key={index} onClick={() => NavigateToCaseInjuryPage(item.CaseId, item.InjuryId)} className="clickable">
                        <td>
                            <FormLabel displayValue={item.Ranking ?? ""} clickable={true}/>
                        </td>
                        <td>
                            <FormLabel displayValue={item.MedicalRegion ?? ""} clickable={true}/>
                        </td>
                        <td>
                            <FormLabel displayValue={item.NonMedicalRegion ?? ""} clickable={true}/>
                        </td>
                        <td>
                            <FormLabel displayValue={item.TypeDescription ?? ""} clickable={true}/>
                        </td>
                        <td>
                            <FormLabel displayValue={item.SideOfBodyDesc ?? ""} clickable={true}/>
                        </td>
                        <td>
                            <FormLabel displayValue={item.Comments ?? ""} clickable={true}/>
                        </td>
                        <td>
                            <CheckboxDisabled displayValue={item.AddedAtReferral ?? false} clickable={true}/>
                        </td>
                    </tr>
                ))}
                {!displayedInjuries && (
                    <NoTableDataToDisplay
                    missingDataDescription='case injuries'
                    numberOfColumnsToSpan={7}
                    />
                )}
                </tbody>
                {filteredInjuries && filteredInjuries.length > 0 && (
                    <div style={{ marginTop: "0.1rem" }}>
                    <ListPageNavigationBar
                        allItems={filteredInjuries}
                        itemsPerPage={10}
                        setItemsToRender={setDisplayedInjuries}
                    />
                    </div>
                )}
            </table>
            <div style={{marginTop: "0.2rem"}}>
                <Button
                    label='Add Injury'
                    handleClick={() => NavigateToCaseInjuryPage()}
                />
            </div>
        </div>
    )
}
export default CaseInjuryTable